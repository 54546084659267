/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import 'focus-visible';
import smoothscroll from 'smoothscroll-polyfill';

import './src/scss/index.scss';

window.jQuery = window.$ = require('jquery');
require('./src/vendor/cssmap-poland/jquery.cssmap.min.js');
window.Masonry = require('masonry-layout');

smoothscroll.polyfill();
